<template>
  <v-card flat>
    <v-snackbar
      v-model="snackbar"
      :color="actionClass"
      timeout="2000"
    >
      {{ message }}
    </v-snackbar>
    <v-card-text class="pb-0"> 
      <v-row>
        <v-col cols="8">
          <v-text-field
          label="Search by Unique ID"
          placeholder="Search by unique ID"
          solo
          :background-color="`${sendyGray20}`"
          prepend-inner-icon="mdi-magnify"
          class="rounded-lg"
          flat
          v-model="search_id"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-btn :color="sendyMidnightBlue20" dark shaped large @click="prefill" :loading="loading"> Search</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-skeleton-loader
      type="card-avatar, article, actions"
      v-if="loading"
    ></v-skeleton-loader>
    <v-card flat v-else>
      <v-card-text>
        <v-form ref="prefillForm" lazy-validation v-model="valid1">
          <v-text-field
          label="Unique ID"
          outlined
          placeholder="Enter Unique ID"
          class=""
          v-model="unique_id"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="Enter Name"
          outlined
          placeholder="Enter full Name"
          class=""
          v-model="name"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="Age"
          outlined
          placeholder="age"
          class=""
          v-model="age"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="Next of Keen"
          outlined
          placeholder="Next of Keen"
          class=""
          v-model="next_of_keen"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="ID No"
          outlined
          placeholder="Id number"
          class=""
          v-model="id_number"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="Mobile"
          outlined
          placeholder="mobile"
          class=""
          v-model="phone"
          readonly
          >
          </v-text-field>

          <v-text-field
          label="Residence"
          outlined
          placeholder=""
          class=""
          v-model="residence"
          readonly
          >
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-text >
        <v-form class="d-flex" ref="form"  lazy-validation v-model="valid">
          <v-text-field
          label="Temperature"
          outlined
          placeholder="Enter your temprature"
          class="text-width"
          v-model="temperature"
          :rules="rules.temperature"
          required
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-btn :color="sendyMidnightBlue20" dark shaped x-large @click="submitTemp" >submit</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import colorMxn from '@/mixins/color_mixins';

export default {
  name: "entry",
  mixins: [colorMxn],
  data() {
    return {
      loading: false,
      loading1: false,
      name: '',
      age: '',
      next_of_keen: '',
      id_number: '',
      phone: '',
      residence: '',
      temperature: '',
      search_id: '',
      unique_id: '',
      message: '',
      actionClass: '',
      snackbar: false,
      valid: true,
      valid1:true,
      rules: {
        temperature: [
          v => v.length <= 2  || 'Max of 2 digits required',
          v => !!v || 'Temperature is required',
          v => /^\d+$/.test(v) || "Only digits are required"
        ],
      },
    }
  },
  methods: {
    ...mapActions(['performGetActions', 'performPostActions']),
    async prefill() {
      this.loading = true;
      const fullPayload = {
        endpoint: `/search-member/${this.search_id}`
      };
      const response = await this.performGetActions(fullPayload);
      if (response.status === 'success' ) {
        this.name = response.data.name;
        this.age = response.data.age;
        this.next_of_keen = response.data.next_of_keen;
        this.id_number = response.data.id_number;
        this.phone = response.data.phone;
        this.residence = response.data.residence;
        this.unique_id = response.data.id;
      }
      if (response.status === "error") {
        this.actionClass = response.status === 'success' ? 'success' : 'error';
        this.message = response.message;
        this.snackbar = true;
        this.loading= false;
        this.reset()
      }
      this.loading= false;
    },
    reset() {
      this.name = "";
      this.age = "";
      this.next_of_keen = "";
      this.id_number = "";
      this.phone = "";
      this.residence = "";
      this.unique_id = "";
    },
    async submitTemp() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.unique_id === '') {
        this.message = "Search unique Id first ";
        this.actionClass= "error";
        this.snackbar = true;
        return;
      }
      this.loading1 = true
      const payload = {
        member_id: this.unique_id,
        temperature: this.temperature,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/register-attendance',
      }
      
      const response = await this.performPostActions(fullPayload);
      this.actionClass = response.status === 'success' ? 'success' : 'error';
      this.message = response.message;
      this.snackbar = true;
      this.loading1 = false;
    }
  }
}
</script>

<style scoped>
.text-width {
  width: 50%;
}
.main-text {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #909399;
}

.sub-text {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #303133;
}
</style>